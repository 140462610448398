import React from 'react';
import { Route, Routes,BrowserRouter } from 'react-router-dom';
import { Navigate} from "react-router-dom";
import './App.css';

const loading = (
	<div className="pt-3 text-center">
	  <div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const Chart = React.lazy(() => import('./pages/chart'));

const App = () =>{
	return(
		<BrowserRouter>
			<React.Suspense fallback={loading}>
				<Routes>
					<Route exact path="/" element={<Navigate to="/chart" />}/>
					<Route exact path="/chart" element={<Chart/>} />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
	)
}

export default App;
